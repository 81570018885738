import styled from 'styled-components';
import {colors, container, grid, h2, h4, mediaQueries} from '@theme/lib';

export const smCustomBreakpoint = '@media screen and (min-width: 480px)';
export const mdCustomBreakpoint = '@media screen and (min-width: 708px)';
export const lgCustomBreakpoint = '@media screen and (min-width: 1120px)';
export const xlCustomBreakpoint = '@media screen and (min-width: 1593px)';

export const StyledFourPillars = styled('section')<{
  pillarSelected: boolean;
  backgroundImage: string | undefined;
}>`
  margin-bottom: 3rem;
  ${mediaQueries.lg} {
    margin-bottom: 4rem;
  }
  background: ${colors.seagrass};
  overflow: hidden;
  ${mediaQueries.lg} {
    background: ${(props) => (props.backgroundImage ? `url(${props.backgroundImage})` : colors.seagrass)};
    max-height: 46.875rem;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 2.75rem;
    transition: background-image ease-out 1s, padding-bottom ease-out 0.5s;
    will-change: padding-bottom, background-image;

    &.closed {
      padding-bottom: 0;

      .four-pillars-wrapper {
        min-height: 35rem;
        height: 35rem;
      }
    }
  }
  .four-pillars {
    &-wrapper {
      position: relative;
      ${smCustomBreakpoint} {
        min-height: 46.875rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      ${mediaQueries.lg} {
        ${container}
        ${grid}
        min-height: 46.875rem;
        height: 46.875rem;
        align-items: center;
        justify-content: space-between;
        will-change: height, min-height;
        transition: height ease-out 0.5s, min-height ease-out 0.5s;
      }
    }
    &-title-wrapper {
      ${mediaQueries.md} {
        padding-bottom: ${(props) => (props.pillarSelected ? '1.875rem' : '0')};
      }
      ${mediaQueries.lg} {
        position: absolute;
        grid-column: 1 / span 3;
        border-radius: 5px;
        padding: ${(props) => (props.pillarSelected ? '1.875rem' : '0')};
        background-color: ${(props) => (props.pillarSelected ? 'rgba(0, 0, 0, 0.45)' : 'transparent')};
        transition: background-color 0.25s ease-out, height 0.5s ease-out;
      }
    }

    &-title {
      ${h2}
      font-family: ${(props) => props.theme.fonts.feature};
      font-weight: 400;
      text-align: center;
      color: ${colors.white};
      text-transform: uppercase;
      padding: 2rem 1.125rem 1.125rem;
      ${smCustomBreakpoint} {
        padding-bottom: 0.125rem;
      }
      ${mediaQueries.lg} {
        text-align: left;
        padding: 0;
      }
      ${xlCustomBreakpoint} {
        max-width: 85%;
      }
    }
    &-carousel {
      display: flex;
      flex-direction: ${(props) => (props.pillarSelected ? 'row' : 'column')};
      flex-wrap: ${(props) => (props.pillarSelected ? 'nowrap' : 'wrap')};
      padding: 0 0.75rem;
      padding-top: ${(props) => (props.pillarSelected ? '0.875rem' : undefined)};
      overflow-x: auto;
      padding-bottom: 1rem;
      ${smCustomBreakpoint} {
        flex-direction: row;
        justify-content: center;
      }
      ${mdCustomBreakpoint} {
        width: ${(props) => (props.pillarSelected ? undefined : '66%')};
        margin: ${(props) => (props.pillarSelected ? undefined : '0 auto')};
      }
      ${lgCustomBreakpoint} {
        width: 100%;
      }
      ${mediaQueries.lg} {
        overflow: initial;
        flex-direction: ${(props) => (props.pillarSelected ? 'column' : 'row')};
        display: block;
      }
      ${xlCustomBreakpoint} {
        flex-wrap: nowrap;
      }
    }
    &-carousel-wrapper {
      background-color: ${(props) => (props.pillarSelected ? colors.gecko : 'transparent')};
      ${mediaQueries.md} {
        grid-column: ${(props) => (props.pillarSelected ? '4 / span 6' : undefined)};
        width: ${(props) => (props.pillarSelected ? '100%' : undefined)};
        align-items: center;
        overflow: ${(props) => (props.pillarSelected ? 'initial' : undefined)};
      }
      ${mediaQueries.lg} {
        background-color: transparent;
        grid-column: ${(props) => (props.pillarSelected ? ' 4 / span 7' : ' 4 / span 6')};
        overflow: initial;
      }
      ${xlCustomBreakpoint} {
        grid-column: ${(props) => (props.pillarSelected ? ' 4 / span 6' : ' 3 / span 8')};
        padding: 0;
        flex-wrap: nowrap;
        width: 100%;
      }
    }
    &-carousel-title {
      ${h4}
      font-family: ${(props) => props.theme.fonts.feature};
      font-weight: 400;
      color: ${colors.white};
      text-align: center;
      text-transform: uppercase;
      display: ${(props) => (props.pillarSelected ? 'block' : 'none')};
      padding-top: 0.8125rem;
      display: inline-block;
      width: 100%;
      ${mediaQueries.lg} {
        display: none;
      }
    }
  }
`;
