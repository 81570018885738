import styled, {css} from 'styled-components';
import {smCustomBreakpoint, xlCustomBreakpoint} from '../FourPillars/styles';
import {colors, h4, mediaQueries, paragraph} from '@theme/lib';

const idleCircleAnimation = css`
  border-color: ${colors.parakeet};
  animation: fadeinout 2s ease-in-out;
  animation-iteration-count: infinite;
  @keyframes fadeinout {
    0%,
    100% {
      border-color: ${colors.parakeet};
    }
    50% {
      border-color: rgba(199, 247, 0, 0.1);
    }
  }
`;

export const StyledPillar = styled('div')<{ selected: boolean; bgimage: string }>`
  position: relative;
  transition: all 0.5s;
  ${smCustomBreakpoint} {
    margin-left: 1rem;
    margin-top: 1rem;
  }
  ${mediaQueries.lg} {
    position: absolute;
    transform: translate(0, -50%);
    margin-top: 0;
    will-change: right, top, height, width, transform;
    transition: all 0.75s;

    // start 'initial' theming
    &.idle:nth-of-type(1) {
      right: 52%;
    }
    &.idle:nth-of-type(2) {
      right: 35%;
    }
    &.idle:nth-of-type(3) {
      right: 18%;
    }
    &.idle:nth-of-type(4) {
      right: 1rem;
    }

    // Start 'active' state theming
    &.carousel:not(.active) {
      right: 1rem;
      transform: translate(0, 0);
    }

    &.carousel:not(.active):nth-of-type(1),
    &.carousel:not(.active):nth-of-type(2) {
      top: 2%;
    }

    &.carousel:not(.active):nth-of-type(1) + &.carousel:not(.active):nth-of-type(2) {
      top: 50%;
      transform: translate(0, -50%);
    }

    &.carousel:not(.active):nth-of-type(3),
    &.carousel:not(.active):nth-of-type(4) {
      bottom: 2%;
    }

    &.carousel:not(.active):nth-of-type(3) + &.carousel:not(.active):nth-of-type(4) {
      bottom: 50%;
      transform: translate(0, 50%);
    }
    // End 'active' state theming
  }
  .pillar {
    &-item {
      display: block;
      border: none;
      background-color: transparent;
      position: relative;
      transition: all 0.5s;
      cursor: pointer;
    }
    &-title-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 80%;
      z-index: 5;
    }
    &-title {
      ${h4}
      font-family: ${(props) => props.theme.fonts.feature};
      color: ${colors.white};
      text-transform: uppercase;
      position: relative;
      ${mediaQueries.lg} {
        font-size: 2rem;
        text-shadow: 1px 0px 18px rgba(0,0,0,0.75);
      }
      &::after {
        content: "";
        display: block;
        width: ${(props) => (props.selected ? '2rem' : '1rem')};
        height: ${(props) => (props.selected ? '1.1875rem' : '0.625rem')};
        background: url(${(props) =>
          props.selected ? '/FourPillars/arrow.svg' : '/FourPillars/arrow-small.svg'});
        background-repeat: no-repeat;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin-top: ${(props) => (props.selected ? '1.125rem' : '0.25rem')};
        ${mediaQueries.lg} {
          display: none;
        }
      }
    }
    &-chip {
      display: block;
      overflow: hidden;
      width: 12.5rem;
      height: 12.5rem;
      border-radius: 50%;
      border: 6px solid transparent;
      transition: all 0.5s;
      ${mediaQueries.md} {
        width: 15.3125rem;
        height: 15.3125rem;
      }

      ${mediaQueries.lg} {
        width: 12.5rem;
        height: 12.5rem;
      }

      ${xlCustomBreakpoint} {
        width: 15.3125rem;
        height: 15.3125rem;
      }

      span {
        position: relative;

        :before {
          position: absolute;
          content: "";
          background-color: black;
          opacity: 0.3;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
      }
    }
    &-image {
      overflow: hidden;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    &-more-info {
      display: ${(props) => (props.selected ? 'block' : 'none')};
      text-align: center;
      padding: 2rem 0 2.875rem;
    }
    &-description {
      ${paragraph}
      line-height: 130%;
      color: ${colors.white};
      font-family: ${(props) => props.theme.fonts.body};
      padding: 0 1.125rem 1.3125rem;
      max-width: 90vw;
      margin: 0 auto;
    }
  }
  &.left {
    align-self: flex-start;
    ${smCustomBreakpoint} {
      align-self: auto;
    }
  }
  &.right {
    align-self: flex-end;
    ${smCustomBreakpoint} {
      align-self: auto;
    }
  }
  &.active {
    align-self: flex-start;
    background-color: ${colors.seagrass};
    animation: fadeIn 0.5s;
    transform: translate(0, 0);
    @keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }
    ${smCustomBreakpoint} {
      margin-left: 0;
      width: 100%;
    }
    ${mediaQueries.md} {
      margin-top: 0;
    }
    ${mediaQueries.lg} {
      align-self: initial;
      margin-left: auto;
      margin-top: auto;
      width: auto;
      background: none;
      top: 50%;
      right: 45%;
      transform: translate(50%, -50%);
      animation: none;
    }

    ${xlCustomBreakpoint} {
      right: 50%;
    }
    .pillar {
      &-item {
        background-image: url(${(props) => props.bgimage});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        ${mediaQueries.lg} {
          background: none;
          position: relative;
          &::after {
            content: "";
            background: url(/FourPillars/close.svg) no-repeat center center ${colors.seagrass};
            border-radius: 7rem;
            width: 3rem;
            height: 3rem;
            position: absolute;
            bottom: 0;
            left: 50%;
            right: 50%;
            transform: translateX(-50%);
            z-index: 5;
          }
        }
      }
      &-title-wrapper {
        width: 100%;
        ${mediaQueries.lg} {
          min-width: 37rem;
        }
      }
      &-title {
        font-size: 2.5rem;
        ${mediaQueries.lg} {
          font-size: 4.5rem;
        }
      }
      &-chip {
        margin: 1rem auto;
        ${mediaQueries.lg} {
          width: 37rem;
          height: 37rem;
        }

        span::before {
          opacity: 0.2;
        }
      }
      &-cta {
        ${mediaQueries.lg} {
          color: ${(props) => props.theme.colors.primary};
          background-color: ${colors.white};
          border: 1px solid ${(props) => props.theme.colors.primary};
        }
      }
    }
  }
  &.idle {
    .pillar-chip {
      ${idleCircleAnimation}
    }
  }
  &.carousel {
    ${mediaQueries.lg} {
      align-self: flex-end;
      margin-left: 0;
      margin-top: 1rem;
    }
    .pillar {
      &-title {
        ${mediaQueries.lg} {
          font-size: 1.5rem;
        }
      }
      &-chip {
        ${mediaQueries.lg} {
          border: 6px solid transparent;
          width: 11.625rem;
          height: 11.625rem;
          ${idleCircleAnimation}

        }
      }
    }
  }
`;

export const StyledMoreInfo = styled('div')<{ selected: boolean }>`
  .pillar {
    &-more-info {
      display: ${(props) => (props.selected ? 'block' : 'none')};
      text-align: center;
      padding: 2rem 0 2.875rem;
    }
    &-description {
      ${paragraph}
      line-height: 130%;
      color: ${colors.white};
      font-family: ${(props) => props.theme.fonts.body};
      padding: 0 1.125rem 1.3125rem;
      ${mediaQueries.md} {
        padding: 1rem 0 2rem;
        height: 0;
      }
    }
  }
  &.active {
    .pillar {
      &-cta {
        ${mediaQueries.lg} {
          color: ${(props) => props.theme.colors.primary};
          background-color: ${colors.white};
          border: 1px solid ${(props) => props.theme.colors.primary};
        }
      }
    }
  }
`;
