import {Dispatch, ReactElement} from 'react';
import {useTablet} from '@/lib/hooks/useTablet';
import StyledButton from '../Button';
import {StyledMoreInfo, StyledPillar} from './styles';
import {LazyMotion, m} from 'framer-motion';
import {PillarFragment} from './__generated__/PillarFragment';
import {ComponentProps} from '@components/ComponentProps';
import Image from "next/image";
import {imageLoaderResponsive} from '@/theme/lib';

export type PillarProps = ComponentProps<PillarFragment> & {
  idx?: number;
  activePillar?: number;
  handleClick?: Dispatch<number | undefined>;
};

export type MoreInfoPillarProps = {
  description: string;
  url: string;
  selected: boolean;
};

const loadFeatures = () =>
  import("./framer.js").then(res => res.default)

export function MoreInfoPillar({ description, url, selected }: MoreInfoPillarProps): ReactElement {
  return (
    <StyledMoreInfo className="pillar-more-info" selected={selected}>
      <LazyMotion features={loadFeatures}>
        <m.p
          animate={{ height: 'auto' }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
          className="pillar-description"
        >
          {description}
        </m.p>
      </LazyMotion>
      <StyledButton className="pillar-cta" href={url}>
        Discover more
      </StyledButton>
    </StyledMoreInfo>
  );
}

export default function Pillar({
  idx,
  title,
  description,
  cta,
  circleImage,
  backgroundImage,
  activePillar,
  handleClick,
}: PillarProps): ReactElement {
  const isTablet = useTablet();
  const isActive = activePillar !== -1 && idx == activePillar;
  const isIdle = activePillar === -1;

  return (
    <StyledPillar
      className={`pillar ${idx != undefined && idx % 2 == 0 ? 'left' : 'right'} ${
        isActive ? 'active' : isIdle ? 'idle' : 'carousel'
      }`}
      selected={isActive}
      bgimage={isTablet ? backgroundImage.lg : backgroundImage.xl}
    >
      <button className="pillar-item" onClick={() => handleClick && handleClick(idx)}>
        <div className="pillar-chip">
          <div className="pillar-title-wrapper">
            <h3 className="pillar-title">{title}</h3>
          </div>
          <Image
            src={circleImage.url}
            alt={circleImage.altText || ''}
            layout="responsive"
            width={isTablet ? 188 : 230}
            height={isTablet ? 188 : 230}
            loader={imageLoaderResponsive(isTablet ? 188 : 230, isTablet ? 188 : 230)}
            className="pillar-image"
          />
        </div>
      </button>
      {isTablet && <MoreInfoPillar description={description} url={cta} selected={isActive} />}
    </StyledPillar>
  );
}
